import React from 'react';
import Default from '../../layouts/default';
import EmailSubscribeBanner from 'components/EmailSubscribeBanner';
import useAlgoliaSearchAPI from '../../hooks/useAlgoliaSearchAPI';
import algoliaIndices from '../../../algolia-indices';
import useBlogHeader from '../../hooks/useBlogHeader';
import { BLOG_CATEGORIES } from '../../constants';
import { graphql } from 'gatsby';
import { ContentfulPageBlogConnection } from 'GatsbyTypes';
import BlogWrapper from '../../components/Blog/Search';
import { FeaturedBlogs } from '../../components/Blog/FeaturedBlogs';
import { BlogPostSection } from '../../components/Blog/BlogPostSection';
import { blogList } from 'utils/blogList';

interface PageData {
  blog: ContentfulPageBlogConnection;
}

const BlogIndex: any = ({ data: { blog } }: { data: PageData }) => {
  const {
    data: searchBlogPosts,
    incrementPage: incrementBlogPostPage,
    hasMoreToShow: hasMoreToShowBlogPosts,
  } = useAlgoliaSearchAPI(algoliaIndices.blogHeaders);

  const {
    data: fixGuidePosts,
    incrementPage: incrementFixGuidesPage,
    hasMoreToShow: hasMoreToShowFixGuidePosts,
  } = useAlgoliaSearchAPI(algoliaIndices.blogCategories, BLOG_CATEGORIES.vulnerabilitiesAndRemediation);

  const { BlogHeader, featuredBlog, subFeaturedBlogs, featuredImageData, featuredImage } = useBlogHeader(blog);

  return (
    <Default>
      <BlogHeader />
      <main className="container">
        <BlogWrapper>
          <FeaturedBlogs {...{ featuredBlog, subFeaturedBlogs, featuredImageData, featuredImage }} />

          <BlogPostSection
            edges={searchBlogPosts}
            isLoadMoreButtonVisible={hasMoreToShowBlogPosts}
            onLoadMore={incrementBlogPostPage}
          />

          <BlogPostSection
            title="Fix Guides"
            edges={fixGuidePosts}
            isLoadMoreButtonVisible={hasMoreToShowFixGuidePosts}
            onLoadMore={incrementFixGuidesPage}
          />
        </BlogWrapper>
      </main>
      <section>
        <EmailSubscribeBanner />
      </section>
      {blogList.map((blog, idx) => (
        <a key={idx} href={blog}></a>
      ))}
    </Default>
  );
};

export default BlogIndex;

export const query = graphql`
  {
    blog: allContentfulPageBlog(limit: 1) {
      edges {
        node {
          seo {
            ...ContentfulSeo
          }
          ...blogFields
        }
      }
    }
  }
`;
