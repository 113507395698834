export const blogList = [
  'https://www.stackhawk.com/blog/top-4-ways-stackhawk-customers-shift-left/',
  'https://www.stackhawk.com/blog/grpc-security-how-stackhawk-scans-grpc-services/',
  'https://www.stackhawk.com/blog/stackhawk-github-dev-first-security-testing-across-the-github-universe/',
  'https://www.stackhawk.com/blog/stackhawk-snyk-from-integrate-to-inte-awesome/',
  'https://www.stackhawk.com/blog/react-csrf-protection-guide-examples-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/vue-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/net-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/what-is-cors/',
  'https://www.stackhawk.com/blog/django-csrf-protection-guide/',
  'https://www.stackhawk.com/blog/announcing-github-insights/',
  'https://www.stackhawk.com/blog/managing-node-and-npm-versions-in-our-projects-best-practices-for-developers/',
  'https://www.stackhawk.com/blog/a-birds-eye-view-demoing-stackhawk-at-blackhat-2023/',
  'https://www.stackhawk.com/blog/understanding-the-attack-surface-of-a-production-api/',
  'https://www.stackhawk.com/blog/fixing-no-access-control-allow-origin-header-present/',
  'https://www.stackhawk.com/blog/react-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/multiple-cookies-and-token-authentication-enhancing-api-security/',
  'https://www.stackhawk.com/blog/secure-code-in-the-age-of-ai-challenges-and-solutions/',
  'https://www.stackhawk.com/blog/july-newsletter-tech-flag-updates-datarobot-automates-api-security-testing/',
  'https://www.stackhawk.com/blog/why-shift-security-left/',
  'https://www.stackhawk.com/blog/lua-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/november-newsletter-2020/',
  'https://www.stackhawk.com/blog/july-newsletter-2020/',
  'https://www.stackhawk.com/blog/august-newsletter-2020/',
  'https://www.stackhawk.com/blog/september-newsletter-2020/',
  'https://www.stackhawk.com/blog/stackhawk-june-newsletter-2022/',
  'https://www.stackhawk.com/blog/django-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/lua-xss-examples-and-prevention/',
  'https://www.stackhawk.com/blog/nodejs-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/typescript-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/typescript-csrf-protection-guide-examples-and-how-to-enable/',
  'https://www.stackhawk.com/blog/september-newsletter-custom-authentication-scripts-stackhawk-semgrep-webinar/',
  'https://www.stackhawk.com/blog/nodejs-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/vue-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/june-newsletter-one-medical-chooses-stackhawk-and-more/',
  'https://www.stackhawk.com/blog/october-newsletter-updated-app-creation-stackhawk-snyk-webinar-and-more/',
  'https://www.stackhawk.com/blog/typescript-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/august-newsletter-new-github-code-scanning-integration-git-repo-mounting-and/',
  'https://www.stackhawk.com/blog/may-newsletter-2021/',
  'https://www.stackhawk.com/blog/spring-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/what-is-cross-site-request-forgery-csrf/',
  'https://www.stackhawk.com/blog/february-newsletter-2021/',
  'https://www.stackhawk.com/blog/stackhawk-december-newsletter-2021/',
  'https://www.stackhawk.com/blog/stackhawk-february-newsletter-2022/',
  'https://www.stackhawk.com/blog/stackhawk-january-newsletter-2022/',
  'https://www.stackhawk.com/blog/laravel-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/stackhawk-july-newsletter-2022/',
  'https://www.stackhawk.com/blog/alternatives-to-whitehat-for-developer-centric-security/',
  'https://www.stackhawk.com/blog/stackhawk-may-newsletter-2022/',
  'https://www.stackhawk.com/blog/nodejs-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/using-stackhawk-in-gitlab-know-before-you-go-live/',
  'https://www.stackhawk.com/blog/vue-csrf-protection-guide-csrf-examples-and-how-to-enable-protection/',
  'https://www.stackhawk.com/blog/stackhawk-april-newsletter-snyk-integration-upcoming-events-and-more/',
  'https://www.stackhawk.com/blog/november-newsletter-scan-telemetry-december-webinars-and-more/',
  'https://www.stackhawk.com/blog/stackhawk-march-newsletter-2022/',
  'https://www.stackhawk.com/blog/software-composition-analysis-sca-overview-and-tooling-guide/',
  'https://www.stackhawk.com/blog/node-js-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/automated-graphql-security-testing/',
  'https://www.stackhawk.com/blog/laravel-cors/',
  'https://www.stackhawk.com/blog/optimizing-security-scans-for-speed-and-accuracy/',
  'https://www.stackhawk.com/blog/announcing-stackhawks-new-snyk-code-integration/',
  'https://www.stackhawk.com/blog/announcing-stackhawk-and-snyks-new-partnership/',
  'https://www.stackhawk.com/blog/rsa-2023-themes-and-observations/',
  'https://www.stackhawk.com/blog/stackhawk-atlassian-working-together-to-help-you-shift-left-the-right-way/',
  'https://www.stackhawk.com/blog/stackhawk-onboarding-3-triaging-and-fixing-findings/',
  'https://www.stackhawk.com/blog/how-stackhawk-meets-compliance-requirements-for-highly-regulated-industries/',
  'https://www.stackhawk.com/blog/scaling-security-across-applications-best-practices-and-strategies/',
  'https://www.stackhawk.com/blog/shifting-left-8-essential-tips-to-evolve-your-appsec-program/',
  'https://www.stackhawk.com/blog/7-top-api-security-tools-and-how-they-can-help-you/',
  'https://www.stackhawk.com/blog/10-web-application-security-threats-and-how-to-mitigate-them/',
  'https://www.stackhawk.com/blog/stackhawk-named-winner-in-2023-global-infosec-awards-at-rsa-2023/',
  'https://www.stackhawk.com/blog/do-you-trust-your-x-forwarded-for-header/',
  'https://www.stackhawk.com/blog/what-is-sql-injection/',
  'https://www.stackhawk.com/blog/what-is-cross-site-scripting-xss/',
  'https://www.stackhawk.com/blog/understanding-grpc-security-how-stackhawk-keeps-your-apis-protected/',
  'https://www.stackhawk.com/blog/stackhawk-and-azure-devops/',
  'https://www.stackhawk.com/blog/web-application-security-checklist-10-improvements/',
  'https://www.stackhawk.com/blog/angular-cors-guide-examples-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/scanning-the-damn-vulnerable-web-app-with-stackhawk/',
  'https://www.stackhawk.com/blog/dast-vs-sast/',
  'https://www.stackhawk.com/blog/10-application-security-best-practices-to-adopt-today/',
  'https://www.stackhawk.com/blog/get-your-entire-flock-secured-stackhawk-launches-team-sized-support/',
  'https://www.stackhawk.com/blog/stackhawk-apis/',
  'https://www.stackhawk.com/blog/8-api-security-best-practices-your-org-needs/',
  'https://www.stackhawk.com/blog/what-is-soc-2-security-testing-and-why-is-it-important/',
  'https://www.stackhawk.com/blog/policy-management-speed-up-scans-and-cover-special-test-cases/',
  'https://www.stackhawk.com/blog/stackhawk-series-b-funding-press-release/',
  'https://www.stackhawk.com/blog/proudly-announcing-stackhawks-series-b/',
  'https://www.stackhawk.com/blog/snyk-and-stackhawk-press-release/',
  'https://www.stackhawk.com/blog/react-excessive-data-exposure-examples-and-prevention/',
  'https://www.stackhawk.com/blog/announcing-deeper-api-security-test-coverage/',
  'https://www.stackhawk.com/blog/improvements-to-the-stackhawk-jira-cloud-integration/',
  'https://www.stackhawk.com/blog/what-is-api-security/',
  'https://www.stackhawk.com/blog/custom-test-data-for-graphql-apis/',
  'https://www.stackhawk.com/blog/hawk-rescan-how-to-validate-fixes-in-a-fraction-of-time/',
  'https://www.stackhawk.com/blog/api-security-testing-vs-api-security-monitoring/',
  'https://www.stackhawk.com/blog/dynamic-application-security-testing-vs-penetration-testing/',
  'https://www.stackhawk.com/blog/how-does-an-api-gateway-improve-security-a-leaders-guide/',
  'https://www.stackhawk.com/blog/application-security-audit-an-in-depth-guide/',
  'https://www.stackhawk.com/blog/how-to-establish-an-application-security-policy/',
  'https://www.stackhawk.com/blog/6-serious-api-security-vulnerabilities-and-how-to-fix-them/',
  'https://www.stackhawk.com/blog/importance-of-web-application-security-three-benefits/',
  'https://www.stackhawk.com/blog/how-does-stackhawk-work/',
  'https://www.stackhawk.com/blog/api-security-owasps-top-10-vulnerabilities-explained/',
  'https://www.stackhawk.com/blog/application-security-risks-4-types-and-how-to-fix-them/',
  'https://www.stackhawk.com/blog/api-security-risks-6-to-be-aware-of-and-how-to-prevent-them/',
  'https://www.stackhawk.com/blog/stackhawk-github-a-saga-in-shift-left-security/',
  'https://www.stackhawk.com/blog/customized-and-configurable-scan-discovery/',
  'https://www.stackhawk.com/blog/custom-test-data-for-rest-apis/',
  'https://www.stackhawk.com/blog/scanning-with-custom-test-scripts/',
  'https://www.stackhawk.com/blog/github-codeql-and-stackhawk-streamlining-security-tooling-in-the-developer-workflow/',
  'https://www.stackhawk.com/blog/building-multi-architecture-docker-images-in-cicd/',
  'https://www.stackhawk.com/blog/kotlin-and-protobuf-tips-and-tricks/',
  'https://www.stackhawk.com/blog/kotlin-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/guide-to-security-in-kotlin/',
  'https://www.stackhawk.com/blog/guide-to-security-in-node-js/',
  'https://www.stackhawk.com/blog/guide-to-security-in-net/',
  'https://www.stackhawk.com/blog/rails-excessive-data-exposure-examples-and-prevention/',
  'https://www.stackhawk.com/blog/angular-excessive-data-exposure-examples-and-prevention/',
  'https://www.stackhawk.com/blog/spring-excessive-data-exposure-examples-and-prevention/',
  'https://www.stackhawk.com/blog/java-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/laravel-excessive-data-exposure-examples-and-prevention/',
  'https://www.stackhawk.com/blog/django-excessive-data-exposure-examples-and-prevention/',
  'https://www.stackhawk.com/blog/what-is-open-redirect/',
  'https://www.stackhawk.com/blog/what-is-path-traversal/',
  'https://www.stackhawk.com/blog/angular-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rust-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/vue-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/kotlin-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/guide-to-security-in-django/',
  'https://www.stackhawk.com/blog/vue-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/what-is-command-injection/',
  'https://www.stackhawk.com/blog/stackhawk-snyk-integration-press-release/',
  'https://www.stackhawk.com/blog/spring-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/django-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/vue-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/spring-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/spring-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-xml-external-entities-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/nodejs-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/developing-with-webhooks/',
  'https://www.stackhawk.com/blog/laravel-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/lua-csrf-protection-guide-examples-and-how-to-enable/',
  'https://www.stackhawk.com/blog/net-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-xml-external-entities-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/django-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/react-xml-external-entities-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/the-zap-fund/',
  'https://www.stackhawk.com/blog/lua-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/lua-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/net-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/net-xss-examples-and-prevention/',
  'https://www.stackhawk.com/blog/nodejs-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/angular-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-csrf-protection-guide-examples-and-how-to-enable/',
  'https://www.stackhawk.com/blog/laravel-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/getting-started-with-the-new-stackhawk-scanner/',
  'https://www.stackhawk.com/blog/new-stackhawk-scanner/',
  'https://www.stackhawk.com/blog/react-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/laravel-xml-external-entities-xxe-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/typescript-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/typescript-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/getting-started-with-the-new-stackhawk-cli/',
  'https://www.stackhawk.com/blog/log4shell-issue-overview-and-stackhawk-response-to-log4j-remote-code/',
  'https://www.stackhawk.com/blog/kotlin-csrf-protection-guide-examples-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/kotlin-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/kotlin-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/angular-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/spring-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/kotlin-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/kotlin-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/kotlin-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/angular-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/laravel-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/react-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/react-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/spring-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/django-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/running-stackhawk-in-cicd/',
  'https://www.stackhawk.com/blog/django-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/golang-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/vue-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/application-security-testing-with-stackhawk-at-devops-experience-2021/',
  'https://www.stackhawk.com/blog/django-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/vue-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/golang-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/angular-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/spring-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/kotlin-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/kotlin-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/node-js-csrf-protection-guide-examples-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/golang-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/react-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/spring-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/rust-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/angular-csrf-protection-guide-examples-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/nodejs-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/rails-broken-access-control-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/laravel-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/rust-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/node-js-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/angular-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rust-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/django-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/django-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-http-strict-transport-security-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/react-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rust-path-traversal-guide-example-and-prevention/',
  'https://www.stackhawk.com/blog/golang-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/laravel-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/nodejs-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/rust-csrf-protection-guide-examples-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/nodejs-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-content-security-policy-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/node-js-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/angular-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/java-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/net-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rust-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/nodejs-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/stackhawk-github-code-scanning/',
  'https://www.stackhawk.com/blog/automated-application-security-testing-with-stackhawk/',
  'https://www.stackhawk.com/blog/golang-cors-guide-what-it-is-and-how-to-enable-it/',
  'https://www.stackhawk.com/blog/react-command-injection-examples-and-prevention/',
  'https://www.stackhawk.com/blog/golang-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rust-sql-injection-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-open-redirect-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rails-path-traversal-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/rust-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/configuring-cors-in-fastapi/',
  'https://www.stackhawk.com/blog/csrf-protection-in-fastapi/',
  'https://www.stackhawk.com/blog/vue-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/react-xss-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/laravel-open-redirect-security-guide/',
  'https://www.stackhawk.com/blog/grpc-cleanup-extension-for-junit-5/',
  'https://www.stackhawk.com/blog/using-jpa-specifications-with-kotlin/',
  'https://www.stackhawk.com/blog/creating-an-audit-trail-for-spring-controllers/',
  'https://www.stackhawk.com/blog/netsparker-vs-stackhawk-application-security-overview/',
  'https://www.stackhawk.com/blog/rapid7-vs-stackhawk-dast-comparison/',
  'https://www.stackhawk.com/blog/security-testing-for-single-page-applications/',
  'https://www.stackhawk.com/blog/acunetix-vs-stackhawk/',
  'https://www.stackhawk.com/blog/veracode-alternatives-for-modern-software-security-teams/',
  'https://www.stackhawk.com/blog/ktor-http-response-and-header-test-helpers/',
  'https://www.stackhawk.com/blog/configuring-cors-for-go/',
  'https://www.stackhawk.com/blog/april-newsletter-2021/',
  'https://www.stackhawk.com/blog/march-newsletter-2021/',
  'https://www.stackhawk.com/blog/january-newsletter-2021/',
  'https://www.stackhawk.com/blog/spring-cors-guide/',
  'https://www.stackhawk.com/blog/building-secure-graphql-apis-with-stackhawk/',
  'https://www.stackhawk.com/blog/rails-cors-guide/',
  'https://www.stackhawk.com/blog/test-driven-security-with-travis-ci-and-docker-compose/',
  'https://www.stackhawk.com/blog/password-authentication-with-bearer-token/',
  'https://www.stackhawk.com/blog/sql-injection-prevention-rails/',
  'https://www.stackhawk.com/blog/rails-csrf-protection-guide/',
  'https://www.stackhawk.com/blog/security-testing-authenticated-app-routes-part-1-cookie-authentication/',
  'https://www.stackhawk.com/blog/sql-injection-prevention-spring/',
  'https://www.stackhawk.com/blog/how-to-migrate-zap-stackhawk/',
  'https://www.stackhawk.com/blog/how-we-built-the-stackhawk-slack-app/',
  'https://www.stackhawk.com/blog/laravel-csrf-protection-guide/',
  'https://www.stackhawk.com/blog/command-injection-ruby/',
  'https://www.stackhawk.com/blog/sql-injection-prevention-django/',
  'https://www.stackhawk.com/blog/proudly-presenting-zapcon/',
  'https://www.stackhawk.com/blog/serverless-security-api-testing/',
  'https://www.stackhawk.com/blog/dynamic-application-security-testing-overview/',
  'https://www.stackhawk.com/blog/how-to-automate-appsec-testing-in-cicd/',
  'https://www.stackhawk.com/blog/burp-suite-enterprise-vs-stackhawk/',
  'https://www.stackhawk.com/blog/security-testing-apis-with-stackhawk-and-swagger/',
  'https://www.stackhawk.com/blog/application-security-testing-with-hawkscan-github-action/',
  'https://www.stackhawk.com/blog/security-testing-authenticated-app-routes-part-2-external-token-authentication-with-auth0/',
  'https://www.stackhawk.com/blog/onboarding-5-stackhawk-software-engineering-integrations/',
  'https://www.stackhawk.com/blog/command-injection-java/',
  'https://www.stackhawk.com/blog/sharing-dependencies-and-gradle-plugins-between-kotlin-springboot-services/',
  'https://www.stackhawk.com/blog/api-security-testing-overview/',
  'https://www.stackhawk.com/blog/how-to-run-standalone-kotlin-with-gradle/',
  'https://www.stackhawk.com/blog/guide-to-zap-application-security-testing/',
  'https://www.stackhawk.com/blog/three-reasons-developers-struggle-with-appsec/',
  'https://www.stackhawk.com/blog/java-xss/',
  'https://www.stackhawk.com/blog/spring-profiles-third-party-services-docker/',
  'https://www.stackhawk.com/blog/slack-integration-announcement/',
  'https://www.stackhawk.com/blog/integrated-microservices-in-kubernetes/',
  'https://www.stackhawk.com/blog/api-security-protection-from-vulnerabilities-with-design-and-testing/',
  'https://www.stackhawk.com/blog/june-newsletter-2020/',
  'https://www.stackhawk.com/blog/application-security-visibility-slack/',
  'https://www.stackhawk.com/blog/snyk-vs-stackhawk-appsec-tool-comparison/',
  'https://www.stackhawk.com/blog/application-security-is-broken/',
  'https://www.stackhawk.com/blog/onboarding-2-authenticated-scanning/',
  'https://www.stackhawk.com/blog/series-a-press-release/',
  'https://www.stackhawk.com/blog/api-security-testing-updates/',
  'https://www.stackhawk.com/blog/october-newsletter-2020/',
  'https://www.stackhawk.com/blog/zapcon-2021-event-recap/',
  'https://www.stackhawk.com/blog/how-security-based-development-should-work/',
  'https://www.stackhawk.com/blog/jira-application-security-integration/',
  'https://www.stackhawk.com/blog/onboarding-guide/',
  'https://www.stackhawk.com/blog/free-plan-announcement/',
  'https://www.stackhawk.com/blog/graphql-application-security-testing/',
  'https://www.stackhawk.com/blog/launch-of-stackhawks-limited-early-access-program/',
  'https://www.stackhawk.com/blog/may-newsletter-2020/',
  'https://www.stackhawk.com/blog/django-xss-examples-prevention/',
  'https://www.stackhawk.com/blog/series-a-financing-announcement/',
  'https://www.stackhawk.com/blog/zap-founder-decides-to-join-stackhawk/',
  'https://www.stackhawk.com/blog/sql-injection-prevention-laravel/',
  'https://www.stackhawk.com/blog/prometheus-metrics-with-springboot-and-grpc-services/',
  'https://www.stackhawk.com/blog/zap-vs-stackhawk-comparison/',
  'https://www.stackhawk.com/blog/ciso-choice-awards-qa-with-scott-gerlach/',
  'https://www.stackhawk.com/blog/march-newsletter-2020/',
  'https://www.stackhawk.com/blog/april-newsletter-2020/',
  'https://www.stackhawk.com/blog/django-cors-guide/',
  'https://www.stackhawk.com/blog/application-security-with-github-actions/',
  'https://www.stackhawk.com/blog/enhance-your-security-program-with-developer-centric-security-test-automation/',
  'https://www.stackhawk.com/blog/december-newsletter-2020/',
  'https://www.stackhawk.com/blog/php-command-injection/',
  'https://www.stackhawk.com/blog/add-appsec-to-circle-ci-pipeline/',
  'https://www.stackhawk.com/blog/general-availability-launch/',
  'https://www.stackhawk.com/blog/zapcon-2021-zap-automation-at-scale/',
  'https://www.stackhawk.com/blog/rails-xss-examples-and-prevention/',
  'https://www.stackhawk.com/blog/appsec-findings-management/',
  'https://www.stackhawk.com/blog/welcoming-zap-founder-simon-bennetts/',
  'https://www.stackhawk.com/blog/stackhawk-onboarding-4-application-security-automation/',
  'https://www.stackhawk.com/blog/fix-bugs-curl-validation/',
  'https://www.stackhawk.com/blog/funding-increased-press-release/',
  'https://www.stackhawk.com/blog/spring-csrf-protection-guide/',
  'https://www.stackhawk.com/blog/laravel-xss/',
  'https://www.stackhawk.com/blog/announcing-our-latest-financing-round/',
  'https://www.stackhawk.com/blog/free-plan-press-release/',
  'https://www.stackhawk.com/blog/february-newsletter-2020/',
  'https://www.stackhawk.com/blog/command-injection-python/',
  'https://www.stackhawk.com/blog/ci-pipeline-security-bug-testing/',
  'https://www.stackhawk.com/blog/application-security-testing-sca-and-dast/',
  'https://www.stackhawk.com/blog/test-driven-security-with-stackhawk-and-spinnaker/',
  'https://www.stackhawk.com/blog/building-an-aws-cross-account-codepipeline-with-gitflow/',
  'https://www.stackhawk.com/blog/rust-broken-object-level-authorization-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/java-broken-authentication-guide-examples-and-prevention/',
  'https://www.stackhawk.com/blog/why-dast-should-be-your-first-application-security-priority/',
  'https://www.stackhawk.com/blog/golang-csrf-protection-guide-examples-and-how-to-enable-it/',
];
