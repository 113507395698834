import EmailSubscribeInput from '../../EmailSubscribeInput';
import React from 'react';
import { BlogCardElement } from '../BlogCardElement';
import { BlogListElement } from '../BlogListElement';
import { ContentfulBlogPostConnection, FeaturedImage } from 'GatsbyTypes';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export const FeaturedBlogs = ({
  featuredBlog,
  featuredImageData,
  featuredImage,
  subFeaturedBlogs,
}: {
  featuredBlog: BlogDetails.IBlog;
  featuredImageData: IGatsbyImageData;
  featuredImage: FeaturedImage;
  subFeaturedBlogs: ContentfulBlogPostConnection;
}): JSX.Element => (
  <>
    <section className="row pb-5">
      <div className={`col-12`}>
        {<BlogCardElement node={featuredBlog} thumbnailImage={featuredImageData} altThumbnailImage={featuredImage} />}
      </div>
    </section>
    <section className={`mb-5`}>
      <div className="sub-featured row">
        <BlogListElement data={subFeaturedBlogs} classList="col-md-6 col-12" />
      </div>
    </section>
    <section className={`container`}>
      <div className="row bg-asphalt py-5 px-4">
        <div className="col-md-6 col-12 mb-5 mb-md-0">
          <p className="fz-18 text-gray-300">
            Subscribe to our newsletter to keep up with all the hottest news from the Hawk's nest.
          </p>
        </div>
        <div className="col-md-6 col-12">
          <EmailSubscribeInput />
        </div>
      </div>
    </section>
  </>
);
